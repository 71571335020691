import * as React from 'react';
import { Container, Grid, AppBar, Box, Toolbar, Typography, IconButton, Divider } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from "../config";

import TransactionItem from "../components/TransactionItem/TransactionItem";
import VerticalBarChart from "../components/Charts/VerticalBarChart";
import PieChart from "../components/Charts/PieChart";
import UserAvatar from '../components/UserAvatar/UserAvatar.js';
import SnackbarAlert from '../components/Snackbar/SnackbarAlert.js';
import DrawerMenu from '../components/DrawerMenu/DrawerMenu.js';

export default function DashboardPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const userFocusBudget = JSON.parse(localStorage.getItem("userFocusBudget"));
  const budgetId = userFocusBudget.id;
  
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [last5Transactions, setLast5Transactions] = React.useState([{}, {}, {}, {}, {}]);
  const [valuesMappedByCategories, setValuesMappedByCategories] = React.useState([]);
  const [incomesVsOutcomes, setIncomesVsOutcomes] = React.useState([]);
  const [errorsArray, setErrorsArray] = React.useState([]);

  let { date } = useParams();

  const fetchLastFiveTransactions = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/expenses?limit=5&budget_id=${budgetId}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        setLast5Transactions(data);
    }).catch((error) => {
        console.log(error);
        setErrorsArray(errorsArray => [...errorsArray, error]);
        console.log(errorsArray);
    });
  }

  const fetchValuesMappedByCategories = () => {
    const url = `${process.env.REACT_APP_API_URL ?? API_URL}/api/values-by-categories?date=${date}&budget_id=${budgetId}`;
    fetch(url, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        setValuesMappedByCategories(data);
    }).catch((error) => {
        console.log(error);
        setErrorsArray(errorsArray => [...errorsArray, error]);
    });
  }

  const fetchIncomesVsOutcomes = () => {
    const url = `${process.env.REACT_APP_API_URL ?? API_URL}/api/incomes-vs-outcomes?date=${date}&budget_id=${budgetId}`;
    fetch(url, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        setIncomesVsOutcomes(data);
    }).catch((error) => {
        console.log(error);
        setErrorsArray(errorsArray => [...errorsArray, error]);
    });
  }

  React.useEffect(() => {
    if(authToken === null)
    {
        navigate(`/`);
    }
    else
    {
        fetchLastFiveTransactions();
        fetchValuesMappedByCategories();
        fetchIncomesVsOutcomes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
        <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => {setIsDrawerOpen(true)}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        MoneyTrack
                    </Typography>
                    <UserAvatar />
                    </Toolbar>
                </AppBar>
            </Box>

            <DrawerMenu isOpen={isDrawerOpen} onMenuClose={() => {setIsDrawerOpen(false)}} activeItem="home" />
        </Container>

        <Container maxWidth="lg" sx={{ py: 1 }}>
            <Grid container spacing={0} >
                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1, boxShadow: 8 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>
                        {last5Transactions.map((item, key) => {
                            return (
                                <TransactionItem key={item.id ?? key} name={item.title} amount={item.amount} transactionType={item.transaction_type} date={item.date} category_name={item.category_id} isExpandable={false} />
                            )
                        })}
                    </Container>
                </Grid>

                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1, boxShadow: 8 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>

                        <Container>
                            <VerticalBarChart data={incomesVsOutcomes} />
                        </Container>
                        <Divider sx={{ m: 1}} />
                        <Container sx={{ display: 'flex', justifyContent: 'center', height: 520 }}>
                            <PieChart data={valuesMappedByCategories}/>
                        </Container>
                    </Container>
                </Grid>
            </Grid>
        </Container>

        <SnackbarAlert isOpen={true}/>
    </>
  );
}
