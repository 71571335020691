import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from '../src/Pages/LoginPage'
import DashboardPage from './Pages/DashboardPage';
import CreateExpensePage from './Pages/CreateExpensePage';
import TransactionsPage from './Pages/TransactionsPage';
import YearSummaryPage from './Pages/YearSummaryPage';
import SummaryPage from './Pages/SummaryPage';

const font = ['Poppins', 'sans-serif'].join(',');

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#111827',
      paper: '#0f172a',
    }
  },
  typography: {
    fontFamily: font
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage/>,
  },
  {
    path: "/dashboard/:date",
    element: <DashboardPage/>,
  },
  {
    path: "/create-expense/:date",
    element: <CreateExpensePage/>,
  },
  {
    path: "/transactions/:date",
    element: <TransactionsPage/>,
  },
  {
    path: "/summary/:date",
    element: <SummaryPage/>,
  },
  {
    path: "/year-review/:date",
    element: <YearSummaryPage/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
