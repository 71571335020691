import * as React from 'react';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import { getCategoryIcon } from "../../commons/commons";
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {  useParams } from 'react-router-dom';
import { API_URL } from "../../config";
import { InputLabel, MenuItem, FormControl, Select, Alert } from '@mui/material';
import dayjs from 'dayjs';

export default function ExpenseForm({expenseTitle = "", expenseDate = null, expenseRecipient = "", expenseAmount = null, expenseCategory, categories}) {
  const authToken = localStorage.getItem("authToken");
  const userFocusBudget = JSON.parse(localStorage.getItem("userFocusBudget"));
  const budgetId = userFocusBudget.id;

  const [category, setCategory] = React.useState(expenseCategory.id);
  const [formData, setFormData] = React.useState({title: "", date: "", category_id: "", recipient: "", amount: "", budget_id: ""});
  const [alert, setAlert] = React.useState({message: "", class: ""});

  let { date } = useParams();

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleChange = (event) => {
    setCategory(event.target.value);

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleDatePickerChange = (date) => {
    var dateToSave = new Date(Date.parse(date["$d"]));
    dateToSave = dateToSave.getFullYear() + '-' + (dateToSave.getMonth() + 1) + '-' + dateToSave.getDate();

    var copiedFormData = formData;
    copiedFormData.date = dateToSave;
    setFormData(copiedFormData);
  };
  
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    formData.amount = formData.amount * 100;
    formData.budget_id = budgetId;

    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/create-transaction`, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
        "Authorization": "Bearer " + authToken
      },
      body: JSON.stringify(formData)
    });

    setAlert({message: `Transaction ${formData.title} saved to database!`, class: 'success'});

    setCategory("");
    setFormData({title: "", date: formData.date, category_id: "", recipient: "", amount: "", budget_id: ""});
    
    setTimeout(() => {
      setAlert({message: null, class: null});
    }, 3000)
  }

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit} onClick={stopPropagation}>
          <TextField id="title" name="title" label="Title" variant="outlined" fullWidth sx={{ my: 1 }} value={expenseTitle} onChange={handleFormChange} required/>

          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                id="date" name="date"
                label="Date"
                sx={{ width: 100 + '%', my: 1 }}
                onChange={handleDatePickerChange}
                defaultValue={dayjs(expenseDate)}
            />
          </LocalizationProvider>

          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="category_id" name="category_id"
              value={category}
              label="Category"
              onChange={handleChange}
              required
            >
              {categories.map((category) => {
                return (
                  <MenuItem value={category.id} key={category.id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {getCategoryIcon(category.category_name)}
                      <div style={{ marginLeft: 8}}>{category.category_name}</div>
                    </div>
                  </MenuItem>
                )
              })};
            </Select>
          </FormControl>

          <TextField id="recipient" name="recipient" label="Recipient" variant="outlined" fullWidth sx={{ my: 1 }} value={expenseRecipient} onChange={handleFormChange}/>

          <TextField id="amount" name="amount" type="number" inputProps={{ step: 0.01 }} label="Amount" variant="outlined" fullWidth sx={{ my: 1 }} value={expenseAmount/100} onChange={handleFormChange} required/>

          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
              Save
          </Button>
      </form>
    </Container>
  );
}
