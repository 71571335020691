import * as React from 'react';
import { Container, AppBar, Box, Toolbar, Typography, IconButton, Grid, Divider } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import { API_URL } from "../config";
import { useParams, useNavigate } from 'react-router-dom';

import PieChart from "../components/Charts/PieChart"
import CategoriesBarChart from '../components/Charts/CategoriesBarChart';
import UserAvatar from '../components/UserAvatar/UserAvatar.js';
import DrawerMenu from '../components/DrawerMenu/DrawerMenu.js';

export default function SummaryPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const userFocusBudget = JSON.parse(localStorage.getItem("userFocusBudget"));
  const budgetId = userFocusBudget.id;

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [valuesMappedByCategories, setValuesMappedByCategories] = React.useState([]);
  const [incomesVsOutcomesForYear, setIncomesVsOutcomesForYear] = React.useState([]);

  let { date } = useParams();

  const fetchValuesMappedByCategories = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/values-by-categories?date=${date}&budget_id=${budgetId}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
        "Authorization": "Bearer " + authToken
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      setValuesMappedByCategories(data);
    }).catch((error) => {
      console.log(error);
    });
  }

  const fetchIncomesVsOutcomesForYear = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/incomes-vs-outcomes-for-year?date=${date}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
        "Authorization": "Bearer " + authToken
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      setIncomesVsOutcomesForYear(data);
    }).catch((error) => {
      console.log(error);
    });
  }

  React.useEffect(() => {
    if(authToken === null)
    {
      navigate(`/`);
    }
    else
    {
      fetchValuesMappedByCategories();
      fetchIncomesVsOutcomesForYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={() => {setIsDrawerOpen(true)}}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  MoneyTrack
                </Typography>
                <UserAvatar />
                </Toolbar>
            </AppBar>
        </Box>

        <DrawerMenu isOpen={isDrawerOpen} onMenuClose={() => {setIsDrawerOpen(false)}} activeItem="summary" />
      </Container>

      <Container maxWidth="lg" sx={{ py: 1 }}>
        <Grid container spacing={0} >

          <Grid item xs={12} md={6} sx={{ height: 'inherit', mt: 2 }} px={{ sm: 2}}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, minHeight: 580, boxShadow: 8 }}>
                <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                    Your last transactions
                </Typography>
                {valuesMappedByCategories.map((element) => {
                    return (
                      <Container sx={{ p: 0 + '!important' }} key={element.name}>
                        <Container sx={{ display: 'flex', justifyContent: 'space-between' }} >
                          <div>{element.name}</div>
                          <div>{element.value}</div>
                        </Container>
                        <Divider sx={{ m: 1}} />
                      </Container>
                    )
                  })}
            </Container>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: 'inherit', mt: 2 }} px={{ sm: 2 }}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, minHeight: 580, boxShadow: 8 }}>
                <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                  Your last transactions
                </Typography>
              <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <PieChart data={valuesMappedByCategories}/>
              </Container>
            </Container>
          </Grid>

          <Grid item xs={12} md={12} sx={{ height: 'inherit', mt: 2}} px={{ sm: 2 }}>
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, width: 100 + '%', boxShadow: 8 }}>
                <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                  Your last transactions
                </Typography>
              <CategoriesBarChart data={incomesVsOutcomesForYear}/>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
