import * as React from 'react';
import { useState } from 'react';
import { Container, Stack, Typography, Skeleton, Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';
import { getCategoryIcon } from "../../commons/commons";
import ExpenseForm from '../ExpenseForm/ExpenseForm';

export default function TransactionItem({ name, amount, transactionType, date, category_name, isExpandable = true, recipient = "not passed", expenseCategoryId, categories }) {
  const [expanded, setExpanded] = useState(false);
  const [category, setCategory] = useState();

  React.useEffect(() => {
    if (categories !== undefined) {
      setCategory(categories.filter((category) => category.category_name === expenseCategoryId)[0]);
    }
  }, [name, expenseCategoryId]);

  const handleExpand = () => {
    if(isExpandable)
    {
      setExpanded(!expanded);
    }
  };

  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        p: 2,
        my: 2,
        borderRadius: 2,
        backgroundColor: '#1f2937',
        boxShadow: 6,
        cursor: 'pointer',
      }}
      onClick={handleExpand}
    >
      <Grid item md={1}>
        <Container sx={{ display: 'flex', justifyContent: 'center', width: 40, p: 0 + '!important' }}>
          {getCategoryIcon(category_name)}
        </Container>
      </Grid>
      <Grid item md={8}>
        <Container>
          <Stack>
            <Typography variant='p' sx={{ fontWeight: 600 }}>{name ? name : <Skeleton variant="rounded" height={22} />}</Typography>
            <Typography variant="body2" color="text.secondary">{date ? date : <Skeleton variant="rounded" height={14} sx={{ mt: 1 }} />}</Typography>
          </Stack>
        </Container>
      </Grid>
      <Grid item md={3}>
        <Container sx={{ p: 0 + '!important' }}>
          {amount ? (transactionType === "expense" ? '- ' : '+ ') + (amount / 100).toLocaleString("pl-PL") + ' PLN' : <Skeleton variant="rounded" height={24} />}
        </Container>
      </Grid>
      {/* Additional content goes here */}
      <Grid item md={12}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Container>
            {/* Additional content goes here */}
              <ExpenseForm expenseTitle={name} expenseDate={date} expenseRecipient={recipient} expenseAmount={amount} expenseCategory={category} categories={categories}/>
          </Container>
        </Collapse>
      </Grid>
    </Grid>
  );
}
