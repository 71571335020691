import * as React from 'react';

export function useLastFiveTransactionsApiRequest(url, params) {
    const authToken = localStorage.getItem("authToken");

    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        fetch(url, {
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*",
                "Authorization": "Bearer " + authToken
            }
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            setData(data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setError(error);
        });
        
    }, [url, JSON.stringify(params)]);

    return { data, loading, error };
}
