import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, TextField, Grid, AppBar, Box, Toolbar, Typography, Button, IconButton } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from "../config";
import { getCategoryIcon } from "../commons/commons"
import TransactionItem from "../components/TransactionItem/TransactionItem";
import UserAvatar from '../components/UserAvatar/UserAvatar.js';
import DrawerMenu from '../components/DrawerMenu/DrawerMenu.js';

import { InputLabel, MenuItem, FormControl, Select, Alert } from '@mui/material';
import {useLastFiveTransactionsApiRequest} from '../hooks/useLastFiveTransactionsApiRequest.js';

const windowWidth = window.innerWidth;

export default function CreateExpensePage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const userFocusBudget = JSON.parse(localStorage.getItem("userFocusBudget"));
  const budgetId = userFocusBudget.id;

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [last5Transactions, setLast5Transactions] = React.useState([{}, {}, {}, {}, {}]);
  const [categories, setCategories] = React.useState([]);

  const [formData, setFormData] = React.useState({title: "", date: "", category_id: "", recipient: "", amount: "", budget_id: ""});
  const [alert, setAlert] = React.useState({message: "", class: ""});

  let { date } = useParams();

  const handleChange = (event) => {
    setAge(event.target.value);

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleDatePickerChange = (date) => {
    var dateToSave = new Date(Date.parse(date["$d"]));
    dateToSave = dateToSave.getFullYear() + '-' + (dateToSave.getMonth() + 1) + '-' + dateToSave.getDate();

    var copiedFormData = formData;
    copiedFormData.date = dateToSave;
    setFormData(copiedFormData);
  };
  
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    formData.amount = formData.amount * 100;
    formData.budget_id = budgetId;

    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/create-transaction`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        },
        body: JSON.stringify(formData)
    });

    setAlert({message: `Transaction ${formData.title} saved to database!`, class: 'success'});

    setAge("");
    setFormData({title: "", date: formData.date, category_id: "", recipient: "", amount: "", budget_id: ""});
    
    setTimeout(() => {
        setAlert({message: null, class: null});
    }, 3000)
  }

  //
  const { data, loading, error } = useLastFiveTransactionsApiRequest(`${process.env.REACT_APP_API_URL ?? API_URL}/api/expenses?limit=5&budget_id=${budgetId}`, { param1: 'value1', param2: 'value2' });
  React.useEffect(() => {
    if (data) {
      setLast5Transactions(data);
    }
  }, [data]);

  const fetchAllCategories = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/categories`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*"
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        setCategories(data);
    }).catch((error) => {
        console.log(error);
    });
  }

  React.useEffect(() => {
    if(authToken === null)
    {
        navigate(`/`);
    }
    else
    {
        // fetchLastFiveTransactions();
        fetchAllCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
        <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => {setIsDrawerOpen(true)}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        MoneyTrack
                    </Typography>
                    <UserAvatar />
                    </Toolbar>
                </AppBar>
            </Box>

            <DrawerMenu isOpen={isDrawerOpen} onMenuClose={() => {setIsDrawerOpen(false)}} activeItem="addExpense" />
        </Container>

        {alert.message && windowWidth < 600 ?
            <Container maxWidth="sm" sx={{ mt:2 }}>
                <Alert variant="outlined" severity={alert.class} onClose={() => {setAlert({message: null, class: null})}}>
                    {alert.message}
                </Alert>
            </Container>
            : ''
        }

        <Container maxWidth="lg" sx={{ py: 1 }}>
            <Grid container spacing={0} >
                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1, boxShadow: 8 }}>
                        <Typography variant='h6' component='div' sx={{ mb: 2 }}>
                            Add Expense
                        </Typography>

                        <Container>
                            <form onSubmit={handleSubmit}>
                                <TextField id="title" name="title" label="Title" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.title} onChange={handleFormChange} required/>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        id="date" name="date"
                                        label="Date"
                                        sx={{ width: 100 + '%', my: 1 }}
                                        onChange={handleDatePickerChange}
                                    />
                                </LocalizationProvider>

                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="category_id" name="category_id"
                                        value={age}
                                        label="Category"
                                        onChange={handleChange}
                                        required
                                    >
                                        {categories.map((category) => {
                                            return (
                                                <MenuItem value={category.id} key={category.id}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {getCategoryIcon(category.category_name)}
                                                        <div style={{ marginLeft: 8}}>{category.category_name}</div>
                                                    </div>
                                                </MenuItem>
                                            )
                                        })};
                                    </Select>
                                </FormControl>

                                <TextField id="recipient" name="recipient" label="Recipient" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.recipient} onChange={handleFormChange}/>

                                <TextField id="amount" name="amount" type="number" inputProps={{ step: 0.01 }} label="Amount" variant="outlined" fullWidth sx={{ my: 1 }} value={formData.amount} onChange={handleFormChange} required/>

                                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ my: 1 }}>
                                    Save
                                </Button>
                            </form>
                        </Container>
                    </Container>
                </Grid>

                <Grid item xs={12} md={6} sx={{ height: 'inherit' }} px={{ sm: 2}}>
                    <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1, boxShadow: 8 }}>
                        <Typography variant='h6' component='div'>
                            Your last transactions
                        </Typography>
                        {last5Transactions?.map((item, index) => {
                            return (
                                <TransactionItem key={item.id ?? index} name={item.title} amount={item.amount} transactionType={item.transaction_type} date={item.date} isExpandable={false} />
                            )
                        })}
                    </Container>
                </Grid>
            </Grid>
        </Container>

        {alert.message && windowWidth > 600 ?
            <Container maxWidth="sm" sx={{ position: 'fixed', bottom: 60, right: 30 }}>
                <Alert variant="outlined" severity={alert.class} onClose={() => {setAlert({message: null, class: null})}}>
                    {alert.message}
                </Alert>
            </Container>
            : ''
        }
    </>
  );
}
