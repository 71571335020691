import * as React from 'react';
import Button from '@mui/material/Button';
import { Add, AutoGraph, Close, FormatListBulleted, ShoppingCart } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { SwipeableDrawer, Divider } from "@mui/material";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function DrawerMenu({isOpen = false, onMenuClose, activeItem}) {
    let { date } = useParams();

    return (
        <SwipeableDrawer anchor='left' open={isOpen} onOpen={() => {}} onClose={() => {onMenuClose()}}>
            <Box p={2} width={26 + 'rem'} textAlign={'center'} role='presentation'>
                <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 + '!important', mb: 4 }}>
                    <Typography variant='h5' component='div'>
                        MoneyTrack side panel
                    </Typography>
                    <Typography variant='h5' component='div'>
                        <Button onClick={() => {onMenuClose()}}><Close/></Button>
                    </Typography>
                </Container>

                <Link to={`/create-expense/${date}`}>
                    <Button variant={activeItem === 'addExpense' ? 'contained' : 'outlined'} color="primary" fullWidth sx={{ my: 1 }}>
                        <Add/>Add expense
                    </Button>
                </Link>

                <Divider/>

                <Link to={`/dashboard/${date}`}>
                    <Button variant={activeItem === 'home' ? 'contained' : 'outlined'} color="primary" fullWidth sx={{ my: 1 }}>
                        <FormatListBulleted/>Home
                    </Button>
                </Link>

                <Link to={`/transactions/${date}`}>
                    <Button variant={activeItem === 'transactions' ? 'contained' : 'outlined'} color="primary" fullWidth sx={{ my: 1 }}>
                        <ShoppingCart/>Transactions
                    </Button>
                </Link>

                <Link to={`/summary/${date}`}>
                    <Button variant={activeItem === 'summary' ? 'contained' : 'outlined'} color="primary" fullWidth sx={{ my: 1 }}>
                        <AutoGraph/>Summary
                    </Button>
                </Link>

                <Link to={`/year-review/${date}`}>
                    <Button variant={activeItem === 'yearReview' ? 'contained' : 'outlined'} color="primary" fullWidth sx={{ my: 1 }}>
                        <AutoGraph/>Summary
                    </Button>
                </Link>
            </Box>
        </SwipeableDrawer>
    );
}