import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const categoryData = [
  {name: "January", value: 342.06},
  {name: "February", value: 251.55},
  {name: "March", value: 2200},
  {name: "April", value: 2510.55},
  {name: "May", value: 2121}
]

export default function SimpleBarChart({data}) {
  const exposedData = data ?? categoryData;

  return (
    <ResponsiveContainer width='100%' height={368}>
      <BarChart
        width={500}
        height={300}
        data={exposedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid /> */}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}
