import * as React from 'react';
import { Divider, IconButton, Typography, Toolbar, Box, AppBar, Container } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import { API_URL } from "../config";
import { useParams, useNavigate } from 'react-router-dom';

import UserAvatar from '../components/UserAvatar/UserAvatar.js';
import TransactionItem from '../components/TransactionItem/TransactionItem.js';
import DrawerMenu from '../components/DrawerMenu/DrawerMenu.js';

function createData(id, date, title, category, recipient, amount, transaction_type) {
  return { id, date, title, category, recipient, amount, transaction_type };
}

export default function TransactionsPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [monthTransactions, setMonthTransactions] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  let { date } = useParams();

  const fetchAllTransactionsForGivenMonth = () => {
    const url = `${process.env.REACT_APP_API_URL ?? API_URL}/api/expenses-by-categories?date=${date}`;
    fetch(url, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        setMonthTransactions(data);
      }).catch((error) => {
        console.log(error);
    });
  }

  const fetchAllCategories = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/categories`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*"
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        setCategories(data);
    }).catch((error) => {
        console.log(error);
    });
  }

  React.useEffect(() => {
    if(authToken === null)
    {
      navigate(`/`);
    }
    else
    {
      fetchAllTransactionsForGivenMonth();
      fetchAllCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={() => {setIsDrawerOpen(true)}}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MoneyTrack
            </Typography>
            <UserAvatar />
            </Toolbar>
          </AppBar>
        </Box>

        <DrawerMenu isOpen={isDrawerOpen} onMenuClose={() => {setIsDrawerOpen(false)}} activeItem="transactions" />
      </Container>

      <Container maxWidth="lg" sx={{ py: 1 }}>
        <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, mt:1, boxShadow: 8 }}>
          <Typography variant='h4' component='div'>
              This month transactions
          </Typography>
          <Divider/>

          {monthTransactions.map((category) => {
            const transactions = [];
            category.transactions.forEach(element => {
              
              transactions.push(createData(element.id, element.date, element.title, category.name, element.recipient, element.amount, element.transaction_type));
            });

            if(transactions.length !== 0)
            {
              return (
                <Container key={category.name}>
                  <Typography variant='h5' component='div' sx={{ py: 2 }}>
                    {category.name}
                  </Typography>

                  {transactions.map((item, key) => 
                    {
                      return <TransactionItem key={item.id ?? key} name={item.title} amount={item.amount} transactionType={item.transaction_type} date={item.date} category_name={item.category} recipient={item.recipient} expenseCategoryId={item.category} categories={categories}/>
                    }
                  )}
                </Container>
              )
            }
            else
            {
              return ("");
            }
          })}
        </Container>
      </Container>
    </>
  );
}
