import React from "react";
import { Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const data01 = [
  {
    name: "Incomes",
    amount: 2600,
  },
  {
    name: "Outcomes",
    amount: 1398,
  }
];

function checkIfNullChart(chartData)
{
  let check = true;

  chartData.forEach(element => {
    if (element.amount !== 0)
    {
      check = false;
    }
      
  });

  return check;
}

export default function VerticalBarChart({data}) {
  const check = checkIfNullChart(data);
  const exposedData = check ? data01 : data;

  return (
    check === false ? (
      <ResponsiveContainer width='100%' aspect={4.0/1.0}>
      <BarChart
      height={100}
      data={exposedData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      layout='vertical'
      >
        <YAxis type="category" dataKey="name" />
        <XAxis type="number" />
        <Tooltip />
        <Bar dataKey="amount" fill="#8884d8" />
        <text x='50%' y='50%' style={{ display: check ? "block" : "none", fontSize: 36, fontWeight: 'bold', fill: 'black', /* backgroundColor: 'blue' not working for some reason */ }} width={200} scaleToFit={true} textAnchor='middle' verticalAnchor='middle'>
          Dummy data
        </text>
      </BarChart>
    </ResponsiveContainer>
    ) : (
      <Stack spacing={1}>
        <Box sx={{display: 'flex'}}>
          <Skeleton width={64} height={32} />
          <Skeleton width={384} height={32} sx={{ ml: 2 }} />
        </Box>
        <Box sx={{display: 'flex'}}>
          <Skeleton width={64} height={32} />
          <Skeleton width={384} height={32} sx={{ ml: 2 }} />
        </Box>
      </Stack>
    )
  );
}
