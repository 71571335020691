import React from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { Box, Container } from "@mui/material";

const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 }
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function checkIfNullChart(chartData)
{
  let check = true;

  chartData.forEach(element => {
    if (element.value !== 0)
    {
      check = false;
    }
      
  });

  return check;
}

export default function CategoriesPieChart({data}) {
  const check = checkIfNullChart(data);
  const exposedData = check ? data01 : data;
  
  return (
    check === false ? (
    <PieChart width={400} height={400}>
      <Pie
        data={exposedData}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={70}
        outerRadius={90}
        fill="#82ca9d"
        label
      >
      {exposedData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
      </Pie>
      <Tooltip />
      <text x='50%' y='50%' style={{ display: check ? "block" : "none", fontSize: 48, fontWeight: 'bold', fill: 'black', /* backgroundColor: 'blue' not working for some reason */ }} width={200} scaleToFit={true} textAnchor='middle' verticalAnchor='middle'>
        Dummy data
      </text>
      <Legend height={36}/>
    </PieChart>
    ) : (
      <>
        <Stack spacing={1} sx={{ mt: 8 }}>
          <Skeleton variant="circular" width={264} height={264} />
          <Container sx={{ display: "flex" }}>
            <Skeleton width={64} height={32} />
            <Skeleton width={64} height={32} sx={{ ml: 2}} />
            <Skeleton width={64} height={32} sx={{ ml: 2}} />
          </Container>
          <Box sx={{ display: "flex", justifyContent: 'center', mt: 0 + '!important' }}>
            <Skeleton width={64} height={32} />
            <Skeleton width={64} height={32} sx={{ ml: 2}} />
          </Box>
        </Stack>
      </>
    )
  );
}

