import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { logout } from '../../commons/commons';
import { useNavigate } from 'react-router-dom';

function getBudgetFromList(budget_id)
{
    const userBudgets = JSON.parse(localStorage.getItem("userBudgets"));
    var budgetToReturn = userBudgets[0];
    
    userBudgets.forEach(element => {
        if(element.id === budget_id)
            budgetToReturn = element;
    });
    
    return budgetToReturn;
}

export default function UserAvatar() {
    const navigate = useNavigate();
    const userBudgets = JSON.parse(localStorage.getItem("userBudgets"));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleBudgetChange = (budget_id) => {
        const budget = getBudgetFromList(budget_id)
        localStorage.setItem("userFocusBudget", JSON.stringify(budget));
        navigate(0)
    };

    return (
        <>
            <Tooltip title="Account settings">
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar alt={localStorage.getItem("userName")} src="/assets/avatars/Blueparrot.png" >{localStorage.getItem("userName")}</Avatar>
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >       
                {userBudgets.map((item) => {
                    return(
                        <MenuItem key={item.id} onClick={() => {handleBudgetChange(item.id)}}>
                            <Avatar /> {item.budget_name}
                        </MenuItem>
                    );
                })}

                <Divider />

                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                
                <MenuItem onClick={() => {
                    logout();
                    navigate("/");
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};