import * as React from 'react';
import { Container, AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import { API_URL } from "../config.js";
import { useParams, useNavigate } from 'react-router-dom';

import UserAvatar from '../components/UserAvatar/UserAvatar.js';
import SimpleBarChart from '../components/Charts/SimpleBarChart.js';
import DrawerMenu from '../components/DrawerMenu/DrawerMenu.js';

export default function TransactionsPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [yearReviewTransactions, setYearReviewTransactions] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  let { date } = useParams();

  const fetchAllCategories = () => {
    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/categories`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*"
        }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      setCategories(data);
    }).catch((error) => {
      console.log(error);
    });
  }

  const fetchYearReview = () => {
    const url = `${process.env.REACT_APP_API_URL ?? API_URL}/api/year-review?date=${date}`;
    fetch(url, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        setYearReviewTransactions(data);
      }).catch((error) => {
        console.log(error);
    });
  }

  React.useEffect(() => {
    if(authToken === null)
    {
      navigate(`/`);
    }
    else
    {
      fetchAllCategories();

      fetchYearReview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="xlg" sx={{ p: 0 + '!important' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={() => {setIsDrawerOpen(true)}}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MoneyTrack
            </Typography>
            <UserAvatar />
            </Toolbar>
          </AppBar>
        </Box>

        <DrawerMenu isOpen={isDrawerOpen} onMenuClose={() => {setIsDrawerOpen(false)}} activeItem={"yearReview"} />
      </Container>

      <Container maxWidth="lg" sx={{ py: 1 }}>
        {categories.map((category) => {
          return(
            <Container sx={{ backgroundColor: '#282a3c', borderRadius: 2, p: 2, my:2, boxShadow: 8 }} key={category.category_name}>
              <Typography variant='h6' component='div'>
                {category.category_name}
                <SimpleBarChart data={yearReviewTransactions[category.id]} />
              </Typography>
            </Container>
          )
        })}
      </Container>
    </>
  );
}
