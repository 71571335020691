import { API_URL } from "../config";
import { Cloud, Egg, BrunchDining, DirectionsBus, LocalGasStation, DirectionsCar, Palette, Cottage, Savings, RestaurantMenu, Medication, Payments } from '@mui/icons-material';

export function logout()
{
    const authToken = localStorage.getItem("authToken");

    fetch(`${process.env.REACT_APP_API_URL ?? API_URL}/api/logout`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "Authorization": "Bearer " + authToken
        }
    });

    window.localStorage.removeItem("authToken");
}

export function getCategoryIcon(category_name)
{
    if(category_name === "Food & alcohol" || category_name === 1)
    {
        return <BrunchDining />
    }
    else if(category_name === "Groceries" || category_name === 2)
    {
        return <Egg />
    }
    else if(category_name === "Eating out" || category_name === 3)
    {
        return <RestaurantMenu />
    }
    else if(category_name === "Transport" || category_name === 4)
    {
        return <DirectionsCar />
    }
    else if(category_name === "Gas" || category_name === 5)
    {
        return <LocalGasStation />
    }
    else if(category_name === "Other transportation" || category_name === 6)
    {
        return <DirectionsBus />
    }
    else if(category_name === "Recreation & culture" || category_name === 7)
    {
        return <Palette />
    }
    else if(category_name === "Housing" || category_name === 8)
    {
        return <Cottage />
    }
    else if(category_name === "Savings & investments" || category_name === 9)
    {
        return <Savings />
    }
    else if(category_name === "Medical & healthcare" || category_name === 10)
    {
        return <Medication />
    }
    else if(category_name === "Income" || category_name === 12)
    {
        return <Payments />
    }
    else return <Cloud />
}